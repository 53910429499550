import firebase from "firebase/app";
import {useEffect, useState} from "react";
import 'firebase/storage';
import {Button, Modal} from 'react-bootstrap-v5';

function sortedVideoArraysEqual(array1: Video[], array2: Video[]): boolean {
    if (array1.length !== array2.length) {
        return false;
    }

    for (let i = 0; i < array1.length; i++) {
        if (!array1[i].equals(array2[i])) {
            return false;
        }
    }

    return true;
}

interface VideoType {
    id: string
    name: string
    position: string
    company: string
    recipientName: string
    recipientCompany: string
    userEmail: string
    dateAdded: Date
    fileName: string
    thumbnailUrl: string
    downloadUrl: string
}

class Video {
    public video: VideoType;

    constructor(video: VideoType) {
        this.video = video;
    }

    positionAndCompany(): string {
        if (!this.video.position) {
            return this.video.company;
        }

        if (!this.video.company) {
            return this.video.position;
        }

        return this.video.position + " | " + this.video.company;
    }

    recipientNameAndCompany(): string {
        if (!this.video.recipientCompany) {
            return this.video.recipientName;
        }

        return this.video.recipientName + " | " + this.video.recipientCompany;
    }

    equals(o: Video): boolean {
        return this.video.id === o.video.id && this.video.dateAdded.getTime() === o.video.dateAdded.getTime() &&
                this.video.name === o.video.name && this.video.recipientName === o.video.recipientName &&
                this.video.recipientCompany === o.video.recipientCompany &&
                this.video.fileName === o.video.fileName &&
                this.video.thumbnailUrl === o.video.thumbnailUrl;
    }
}

interface Props {
    selectSection: (section: string) => void;
}

function Videos(props: Props) {

    const [videos, setVideos] = useState<Video[]>([]);
    const [currentVideo, setCurrentVideo] = useState<null| VideoType>(null);
    const [displayModal, setDisplayModal] = useState<boolean>(false);

    useEffect(() => {
        let videosCollection = firebase.firestore().collection("UserData").doc(firebase.auth().currentUser?.uid)
            .collection("Videos").orderBy("dateAdded");
        videosCollection.onSnapshot(snapshot => {
            const allVideoPromises: Promise<Video>[] = [];

            snapshot.forEach( video => {
                let data = video.data() || {};

                let folderRef = firebase.storage().ref().child("UploadedVideos/" + firebase.auth().currentUser?.uid)

                let videoPromise = folderRef.child(data.thumbnailFileName).getDownloadURL()
                    .catch(e => {
                        console.log(e);
                    })
                    .then(thumbnailUrl => {
                    return folderRef.child(data.fileName).getDownloadURL()
                        .catch(e => {
                            console.log(e);
                        })
                        .then(videoUrl => {
                        return new Video({
                            id: video.id,
                            name: data.name,
                            position: data.position,
                            company: data.company,
                            recipientName: data.recipientName,
                            recipientCompany: data.recipientCompany,
                            userEmail: data.userEmail,
                            dateAdded: new Date(data.dateAdded.seconds * 1000),
                            fileName: data.fileName,
                            thumbnailUrl: thumbnailUrl,
                            downloadUrl: videoUrl
                        });
                    });
                });

                allVideoPromises.push(videoPromise);
            });

            Promise.all(allVideoPromises).then( allVideos => {
                allVideos.sort((v1, v2) => v2.video.dateAdded.getTime() - v1.video.dateAdded.getTime() );

                if (!sortedVideoArraysEqual(allVideos, videos)) {
                    setVideos(allVideos);
                }
            });
        });
    });

    function deleteVideo(id: string) {
        if (window.confirm('Skutečně chcete video smazat?')) {

            const docRef = firebase.firestore().collection("UserData")
                .doc(firebase.auth().currentUser?.uid)
                .collection("Videos")
                .doc(id);

            docRef.get().then(video => {
                let folderRef = firebase.storage().ref().child("UploadedVideos/" + firebase.auth().currentUser?.uid);
                let data = video.data() || {};
                const videoRef = folderRef.child(data.fileName);
                const thumbnailRef = folderRef.child(data.thumbnailFileName);
                videoRef.delete().then(value => {
                    return thumbnailRef.delete().then(value => {
                        return docRef.delete().then(video => {
                            console.log("video deleted");
                        });
                    });
                });
            });
        }
    }


    return <>
        <div className="content-right-upper">
            <h3 className="content-right-upper-heading">Video reference</h3>
            <button type="button" data-toggle="modal" data-target="#open-modal" className="get-qr" onClick={e => {e.preventDefault(); props.selectSection('qrCodes')}}>Získat QR kódy</button>
        </div>

        <Modal show={displayModal} size="xl">
            <Modal.Body >
                <video style={{
                    right: 0,
                    bottom: 0,
                    maxWidth: "100%",
                    maxHeight: "100%",
                    width: "auto",
                    height: "auto"
                }} controls autoPlay={true}>
                    <source src={currentVideo ? currentVideo.downloadUrl: ""} type="video/mp4" />
                </video>

                <div className="modal-lower">
                    <div className="modal-lower-left">
                        <p className="modal-lower-date">{currentVideo ? Intl.DateTimeFormat('cs-CZ').format(currentVideo.dateAdded) : ""}</p>
                        <p className="modal-lower-name">{currentVideo ? currentVideo.name : ""}</p>
                    </div>
                    <div className="modal-lower-right">
                        <button className="close-btn" onClick={e => setDisplayModal(false)}>Zavřít</button>
                        <a download="video" target="_blank" href={currentVideo ? currentVideo.downloadUrl : "#"} className="download-btn">Stáhnout</a>
                    </div>
                </div>
            </Modal.Body>
        </Modal>

        {videos.length === 0 ?
            <div className="row justify-content-center mt-4">
                <h1>Zatím nemáte nahraná žádná videa.</h1>
            </div>
            :
            <div id="all-videos" className="row mt-4">
                {videos.map((video) =>
                    <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                        <div className="video-box">
                            <div className="video-box-img">
                                <a href="#" onClick={e => {
                                    e.preventDefault();
                                    setCurrentVideo(video.video);
                                    setDisplayModal(true);
                                }}><img className="img-fluid" src={video.video.thumbnailUrl} alt=""/></a>
                            </div>
                            <p className="video-date">{Intl.DateTimeFormat('cs-CZ').format(video.video.dateAdded)}</p>

                            <div className="video-box-data">
                                <div className="video-delete">
                                    <a href="#" onClick={event => {
                                        event.preventDefault();
                                        deleteVideo(video.video.id)
                                    }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                            <defs>
                                                <style>{`.a{fill:none;}.b{fill:#a6b3bf;}`}</style>
                                            </defs>
                                            <rect className="a" width="24" height="24" transform="translate(0 0)"/>
                                            <path className="b"
                                                  d="M5.21,19.507a3.016,3.016,0,0,1-2.993-2.77L1.306,4.876H.75a.75.75,0,0,1-.1-1.493l.1-.006H4.064v-1.5A1.878,1.878,0,0,1,5.94,0h5.627a1.878,1.878,0,0,1,1.876,1.876v1.5h3.315a.75.75,0,0,1,.1,1.493l-.1.006H16.2l-.912,11.861a3.015,3.015,0,0,1-2.993,2.77Zm-1.5-2.885A1.5,1.5,0,0,0,5.073,18l.137.006H12.3a1.508,1.508,0,0,0,1.5-1.386l.9-11.746H2.809ZM5.565,1.876v1.5h6.379v-1.5a.377.377,0,0,0-.3-.368L11.567,1.5H5.94A.376.376,0,0,0,5.565,1.876ZM6.911,13.314a.75.75,0,0,1-.127-.971l.068-.087.843-.942-.942-.843a.75.75,0,0,1,.912-1.185l.088.068.942.842.842-.941a.75.75,0,0,1,1.186.912l-.068.088-.843.942.942.842a.75.75,0,0,1-.913,1.186l-.087-.068-.942-.843-.843.942a.749.749,0,0,1-1.058.059Z"
                                                  transform="translate(3.252 2.251)"/>
                                        </svg>
                                    </a>
                                </div>
                                <h5 className="video-author">{video.video.name}</h5>
                                <p className="video-author-desc">{video.positionAndCompany()}<br/>
                                    {video.recipientNameAndCompany()}<br/>
                                    {video.video.userEmail}</p>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        }
    </>
}

export default Videos;